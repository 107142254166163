import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Container404 = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
`;

const NotFoundPage = () => (
    <Layout>
        <Container404>
            <SEO title="404: Not found" />
            <h1>NOT FOUND</h1>
        </Container404>
    </Layout>
);

export default NotFoundPage;
